.my-spinner {
    position: absolute;
    left: 50%;
    top: 25%;
    z-index: 2000;
}

.jvectormap-tip {
    position: absolute;
    display: block;
    border: solid 1px #CDCDCD;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
    z-index: 11;
}

.leaflet-container {
    height: 400px;
    width: 800px;
}